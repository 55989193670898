import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_agenda()
})

function dhsv_vc_agenda() {
  $('.dhsv_vc_agenda').each(function () {
    $('.js-speech', $(this)).on({
      mouseenter: function () {
        $('.js-speech--tooltip').fadeOut(100)
        if ($(this).offset().left > $(window).width() / 2) {
          $('.js-speech--tooltip', $(this)).addClass('open-left')
        }
        $('.js-speech--tooltip', $(this)).stop(true, true).fadeIn()
      },
      mouseleave: function () {
        $('.js-speech--tooltip', $(this)).fadeOut(100)
      },
    })
  })
}
