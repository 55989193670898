import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_post_teaser()
})

$(window).resize(function () {
  dhsv_vc_post_teaser()
})

/*
 * Trimming excerpt text & adding dots after that
 */
const ellipsizeTextBox = (items, row) => {
  items.each(function (index, el) {
    const rowHeight = Math.round(parseFloat($(el).css('line-height')))
    let wordArray = $(el).data('text').split(' ')
    el.innerHTML = wordArray.join(' ')
    while ($(el).height() > row * rowHeight) {
      wordArray.pop()
      el.innerHTML = wordArray.join(' ') + '...'
    }
  })
}

function dhsv_vc_post_teaser() {
  $('.dhsv_vc_post_teaser').each(function () {
    const rows = $(this).hasClass('.post-item--full') ? 4 : 3
    const postTeasers = $('.entry-summary', $(this))
    ellipsizeTextBox(postTeasers, rows)
  })
}
