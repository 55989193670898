// logs its argument and a given tag
export const log = tag => x => {
  console.log(tag, x) // eslint-disable-line
  return x
}

export const diff = (arr, arr2) => {
  var ret = []
  arr.sort()
  arr2.sort()
  for (var i = 0; i < arr.length; i += 1) {
    if (arr2.indexOf(arr[i]) > -1) {
      ret.push(arr[i])
    }
  }
  return ret.length > 0 ? true : false
}

export const unique = array => {
  var a = [...array]
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}
