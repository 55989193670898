import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_anmeldeformular()
})

function dhsv_vc_anmeldeformular() {
  // $('.dhsv_vc_anmeldeformular').each(function() {
  //   const queryString = window.location.search
  //   const urlParams = new URLSearchParams(queryString)
  //   const $select = $(this).find('.populate-events select')
  //   $select.val(urlParams.get('id'))
  // })

  $('.dhsv_vc_anmeldeformular').each(function () {
    $(this).find('li.gf_readonly textarea').attr('readonly', 'readonly')
  })
}
