/* Don't forget to add string 'events' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import * as _ from 'lodash'
import { diff } from '../../util/functional'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: {
    events: [],
    filters: {},
  },
  activeFilters: {
    dhsv_event_type: [],
    dhsv_event_theme: [],
    months: [],
  },
  filteredData: [],
  page: 0,
}

const LOAD_EVENTSLIST_DATA = 'LOAD_EVENTSLIST_DATA'
const LOAD_EVENTSLIST_DATA_SUCCESS = 'LOAD_EVENTSLIST_DATA_SUCCESS'
const LOAD_EVENTSLIST_DATA_FAILURE = 'LOAD_EVENTSLIST_DATA_FAILURE'
const GET_EVENTSLIST_ITEMS = 'GET_EVENTSLIST_ITEMS'
const SET_EVENTSLIST_PAGE = 'SET_EVENTSLIST_PAGE'
const SET_EVENTSLIST_ACTIVE_FILTERS = 'SET_EVENTSLIST_ACTIVE_FILTERS'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_EVENTSLIST_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_EVENTSLIST_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: { ...state.data, ...payload },
        filteredData: [...payload.events],
      }

    case LOAD_EVENTSLIST_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case GET_EVENTSLIST_ITEMS:
      return {
        ...state,
        filteredData: payload.events,
        activeFilters: payload.activeFilters,
        page: 0,
      }

    case SET_EVENTSLIST_PAGE:
      return {
        ...state,
        page: payload,
      }

    case SET_EVENTSLIST_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: payload,
      }

    default:
      return state
  }
}

export const loadData = showPastEvents => (dispatch, getState) => {
  if (getState().events.isLoaded) return

  dispatch({ type: LOAD_EVENTSLIST_DATA })

  const format = window.siteLang.format
  let urlParams = []
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    //
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  urlParams.push(`lang=${window.siteLang.current}`)
  urlParams.push(`past_events=${showPastEvents ? 'yes' : 'no'}`)

  return fetch(`${apiBaseUrl}events?${urlParams.join('&')}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_EVENTSLIST_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_EVENTSLIST_DATA_FAILURE,
      })
    })
}

export const setEventsFilteredData = filters => (dispatch, getState) => {
  const events = [...getState().events.data.events]
  let filteredEvents = [...events]
  const filtersData = { ...getState().events.data.filters }

  Object.keys(filters).map(key => {
    if (filters[key].length) {
      filteredEvents = filteredEvents.filter(item =>
        diff(filters[key], item[key])
      )
    }
  })

  const getPostCount = (filterName, id) => {
    let posts = [...events]
    let eventFilters = _.cloneDeep(filters)
    let sameTaxonomy = false
    let sameFilter = false
    if (eventFilters[filterName].length) {
      sameTaxonomy = true
    }

    if (eventFilters[filterName].indexOf(id) === -1) {
      eventFilters[filterName].push(id)
    } else {
      sameFilter = true
    }

    Object.keys(eventFilters).map(key => {
      if (eventFilters[key].length) {
        posts = posts.filter(item => diff(eventFilters[key], item[key]))
      }
    })

    let postsCount = sameTaxonomy
      ? posts.length - filteredEvents.length
      : posts.length

    if (sameFilter) {
      posts = posts.filter(item => diff([id], item[filterName]))

      postsCount = posts.length
    }

    return postsCount
  }

  Object.keys(filtersData).map(key => {
    // filtersData[key].length &&
    filtersData[key].map(filter => {
      return {
        ...filter,
        count: getPostCount(key, filter.id),
      }
    })
  })

  return dispatch({
    type: GET_EVENTSLIST_ITEMS,
    payload: {
      events: filteredEvents,
      activeFilters: filters,
    },
  })
}

export const changePage = page => dispatch => {
  return dispatch({
    type: SET_EVENTSLIST_PAGE,
    payload: page,
  })
}
