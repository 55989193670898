/* Don't forget to add string 'jobs' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: {
    jobs: [],
    filters: {},
  },
  activeFilters: {
    experience: [],
    focus: [],
    locations: [],
  },
  filteredData: [],
  page: 0,
  count: 0,
}

const LOAD_JOBSFINDER_DATA = 'LOAD_JOBSFINDER_DATA'
const LOAD_JOBSFINDER_DATA_SUCCESS = 'LOAD_JOBSFINDER_DATA_SUCCESS'
const LOAD_JOBSFINDER_DATA_FAILURE = 'LOAD_JOBSFINDER_DATA_FAILURE'
const GET_JOBSFINDER_ITEMS = 'GET_JOBSFINDER_ITEMS'
const SET_JOBSFINDER_PAGE = 'SET_JOBSFINDER_PAGE'
const SET_JOBSFINDER_ACTIVE_FILTERS = 'SET_JOBSFINDER_ACTIVE_FILTERS'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_JOBSFINDER_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_JOBSFINDER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: { ...state.data, ...payload },
        filteredData: [...payload.jobs],
        count: payload.jobs.length,
      }

    case LOAD_JOBSFINDER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case GET_JOBSFINDER_ITEMS:
      return {
        ...state,
        filteredData: payload.jobs,
        count: payload.jobs.length,
        activeFilters: payload.activeFilters,
        page: 0,
      }

    case SET_JOBSFINDER_PAGE:
      return {
        ...state,
        page: payload,
      }

    case SET_JOBSFINDER_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: payload,
      }

    default:
      return state
  }
}

export const loadData = () => (dispatch, getState) => {
  if (getState().jobs.isLoaded) return

  dispatch({ type: LOAD_JOBSFINDER_DATA })

  const format = window.siteLang.format
  let urlParams = ''
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    urlParams = `?lang=${window.siteLang.current}`
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  return fetch(`${apiBaseUrl}jobs${urlParams}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_JOBSFINDER_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_JOBSFINDER_DATA_FAILURE,
      })
    })
}

export const setJobsFilteredData = filters => (dispatch, getState) => {
  const jobs = [...getState().jobs.data.jobs]
  let filteredJobs = [...jobs]
  let filtersData = { ...getState().jobs.data.filters }

  const diff = (arr, arr2) => {
    var ret = []
    arr.sort()
    //console.log(arr2) //eslint-disable-line
    arr2.sort()
    for (var i = 0; i < arr.length; i += 1) {
      if (arr2.indexOf(arr[i]) > -1) {
        ret.push(arr[i])
      }
    }
    return ret.length > 0 ? true : false
  }

  Object.keys(filters).map(key => {
    if (filters[key].length) {
      filteredJobs = filteredJobs.filter(item => diff(filters[key], item[key]))
    }
  })

  const getPostCount = (filterName, id) => {
    let posts = [...jobs]
    let jobFilters = _.cloneDeep(filters)
    let sameTaxonomy = false
    let sameFilter = false
    if (jobFilters[filterName].length) {
      sameTaxonomy = true
    }

    if (jobFilters[filterName].indexOf(id) === -1) {
      jobFilters[filterName].push(id)
    } else {
      sameFilter = true
    }

    Object.keys(jobFilters).map(key => {
      if (jobFilters[key].length) {
        posts = posts.filter(item => diff(jobFilters[key], item[key]))
      }
    })

    let postsCount = sameTaxonomy
      ? posts.length - filteredJobs.length
      : posts.length

    if (sameFilter) {
      posts = posts.filter(item => diff([id], item[filterName]))

      postsCount = posts.length
    }

    return postsCount
  }

  Object.keys(filtersData).map(key => {
    filtersData[key].length &&
      filtersData[key].map(filter => {
        filter.currentCount = getPostCount(key, filter.id)
      })
  })

  return dispatch({
    type: GET_JOBSFINDER_ITEMS,
    payload: {
      jobs: filteredJobs,
      activeFilters: filters,
    },
  })
}

export const changePage = page => dispatch => {
  return dispatch({
    type: SET_JOBSFINDER_PAGE,
    payload: page,
  })
}
