import $ from 'jquery'

$(window).load(function () {
  dhsv_vc_animation_header()
})

function dhsv_vc_animation_header() {
  const anchor = $('.js-filters-anchor')
  const fixedFilters = $filters => {
    if (anchor.length) {
      if ($(window).scrollTop() >= anchor.offset().top - 125) {
        anchor.css('height', $filters.outerHeight())
        $filters.addClass('fixed')
      } else {
        $filters
          .removeClass('fixed')
          .find('.js-filters-dropdown')
          .css('display', '')
        anchor.css('height', '')
      }
    }
  }

  $('.dhsv_vc_animation_header').each(function () {
    const $this = $(this)
    const $filters = $this.find('.js-filters-wrapper')
    fixedFilters($filters)

    const filteredList = $('.js-filtered-list')
    $this.on('click', '.dropdown__item label', function () {
      $('html, body').animate(
        {
          scrollTop: filteredList.offset().top - 125,
        },
        600
      )
    })

    $(window).on('scroll', function () {
      fixedFilters($filters)
    })
  })
}
