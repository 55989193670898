// import external dependencies
import 'jquery'
import 'clip-path-polyfill/clip-path-polyfill'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js'; import './autoload/_hyphenate.js'; import './autoload/_matchHeight.js'; import './autoload/_object-fit.js'; import './autoload/_smoothScroll.js'; import './autoload/iframeResizer.contentWindow.min.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/agenda/main.js'; import '../vc_elements/animation_header/main.js'; import '../vc_elements/anmeldeformular/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/career/main.js'; import '../vc_elements/history_item/main.js'; import '../vc_elements/icon_block/main.js'; import '../vc_elements/image_gallery/main.js'; import '../vc_elements/intro/main.js'; import '../vc_elements/lectures_section/main.js'; import '../vc_elements/lightbox_image/main.js'; import '../vc_elements/link_text_box/main.js'; import '../vc_elements/post_teaser/main.js'; import '../vc_elements/reference_teasers/main.js'; import '../vc_elements/socials_slider/main.js'; import '../vc_elements/stories_slider/main.js'; import '../vc_elements/teasers_slider/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/testimonials_slider/main.js'; import '../vc_elements/text_teaser/main.js'; import '../vc_elements/video/main.js'; import '../vc_elements/vision/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
