import $ from 'jquery'
import Flickity from 'flickity'

$(document).ready(() => {
  dhsv_vc_teasers_slider()
})

function dhsv_vc_teasers_slider() {
  $('.dhsv_vc_teasers_slider .js-teasers-slider').each((i, el) => {
    const flkty = new Flickity(el, {
      wrapAround: false,
      pageDots: false,
      cellAlign: 'left',
      on: {
        ready: function () {
          setFadedSlide(this, 0)
        },
        change: function (index) {
          setFadedSlide(this, index)
        },
      },
    })

    $(window).on('resize', () => {
      $(el).find('.flickity-viewport').css('height', '')
      flkty.resize()
    })
  })
}

function setFadedSlide(slider, index) {
  if (slider.cells.length > 4) {
    slider.cells.forEach(el => {
      $(el.element).removeClass('is-faded')
    })
    const inactiveIndex =
      index + 4 > slider.cells.length - 1
        ? index + 4 - slider.cells.length
        : index + 4
    $(slider.cells[inactiveIndex].element).addClass('is-faded')
  }
}
