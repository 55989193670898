import $ from 'jquery'
import anime from 'animejs/lib/anime.es.js'

$(document).ready(function () {
  dhsv_vc_vision()
})

const randRange = { min: -4, max: 4 }

const generateRandomPoint = number => {
  let result = parseInt(
    number + Math.random() * (randRange.max - randRange.min) + randRange.min
  )
  // console.log(`${number} - ${result}`) // eslint-disable-line
  return result > 100 ? 100 : result < 0 ? 0 : result
}
const generatePathFromCoords = coords => {
  let result = ''
  coords.forEach(coord => {
    result += `${coord.x} ${coord.y} `
  })

  return result.trim()
}

function dhsv_vc_vision() {
  $('.dhsv_vc_vision').each(function () {
    let shape1 = {
      points: [],
      data: [
        [
          { x: 17, y: 15 },
          { x: 85, y: 35 },
          { x: 10, y: 95 },
        ],
        [
          { x: 25, y: 20 },
          { x: 92, y: 45 },
          { x: 0, y: 85 },
        ],
        [
          { x: 20, y: 17 },
          { x: 90, y: 40 },
          { x: 5, y: 95 },
        ],
        [
          { x: 23, y: 21 },
          { x: 85, y: 40 },
          { x: 8, y: 90 },
        ],
      ],
    }

    let shape2 = {
      points: [],
      data: [],
    }

    let shape3 = {
      points: [],
      data: [],
    }

    shape1.data.forEach(el => {
      let randomCoords = []
      let randomCoords2 = []

      el.forEach(coord => {
        randomCoords.push({
          x: generateRandomPoint(coord.x),
          y: generateRandomPoint(coord.y),
        })
        randomCoords2.push({
          x: generateRandomPoint(coord.x),
          y: generateRandomPoint(coord.y),
        })
      })

      shape2.data.push(randomCoords)

      shape3.data.push(randomCoords2)

      shape1.points.push({
        value: generatePathFromCoords(el),
      })

      shape2.points.push({
        value: generatePathFromCoords(randomCoords),
      })

      shape3.points.push({
        value: generatePathFromCoords(randomCoords2),
      })
    })

    $('.js-info').each((i, el) => {
      let properties = {
        left: [],
        top: [],
      }

      shape1.data.forEach(el => {
        properties.left.push(`${el[i].x}%`)
        properties.top.push(`${el[i].y}%`)
      })
      $(el).css({
        left: properties.left[properties.left.length - 1],
        top: properties.top[properties.top.length - 1],
      })

      anime({
        targets: el,
        left: properties.left,
        top: properties.top,
        easing: 'cubicBezier(.5, 0, .5, 1)',
        duration: 25000,
        loop: true,
      })
    })

    $('.js-polymorph').each((i, el) => {
      let points = []
      switch ($(el).data('id')) {
        case 'polymorph1':
          $(el).attr('points', shape1.points[shape1.points.length - 1].value)
          points = shape1.points
          break
        case 'polymorph2':
          $(el).attr('points', shape2.points[shape1.points.length - 1].value)
          points = shape2.points
          break
        case 'polymorph3':
          $(el).attr('points', shape3.points[shape1.points.length - 1].value)
          points = shape3.points
          break
      }

      if (points) {
        anime({
          targets: el,
          points: points,
          easing: 'cubicBezier(.5, 0, .5, 1)',
          duration: 25000,
          loop: true,
        })
      }
    })
  })
}
