import $ from 'jquery'
import 'flickity-fade'
import Flickity from 'flickity-as-nav-for'

$(document).ready(() => {
  dhsv_vc_testimonials_slider()
})

function dhsv_vc_testimonials_slider() {
  $('.dhsv_vc_testimonials_slider').each((i, el) => {
    // init stage slider (background image)
    new Flickity($('.stage-slider', el)[0], {
      pageDots: false,
      draggable: false,
      fade: true,
      adaptiveHeight: true,
      prevNextButtons: true,
    })
    // init navigation slider (icons)
    const navSlider = new Flickity($('.nav-slider', el)[0], {
      prevNextButtons: false,
      pageDots: false,
      // cellAlign: 'right',
      contain: true,
      asNavFor: $('.stage-slider', el)[0],
    })
    // init content slider (title, description, link)
    const contentSlider = new Flickity($('.content-slider', el)[0], {
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      fade: true,
      adaptiveHeight: true,
    })

    navSlider.on('select', function (index) {
      if (typeof index == 'number') {
        contentSlider.select(index)
      }
    })
  })
}
