import $ from 'jquery'
import Flickity from 'flickity'

$(document).ready(() => {
  dhsv_vc_socials_slider()
})

function dhsv_vc_socials_slider() {
  $('.dhsv_vc_socials_slider .js-socials-slider').each((i, el) => {
    new Flickity(el, {
      wrapAround: true,
      pageDots: false,
      cellAlign: 'left',
      contain: true,
      on: {
        ready: function () {
          setFadedSlide(this, 0)
        },
        change: function (index) {
          setFadedSlide(this, index)
        },
      },
    })
  })
}

function setFadedSlide(slider, index) {
  if (slider.cells.length > 3) {
    slider.cells.forEach(el => {
      $(el.element).removeClass('is-faded')
    })
    const inactiveIndex =
      index + 3 > slider.cells.length - 1
        ? index + 3 - slider.cells.length
        : index + 3
    $(slider.cells[inactiveIndex].element).addClass('is-faded')
  }
}
