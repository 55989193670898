import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import jobs from './jobs'
import posts from './posts'
import updates from './updates'
import events from './events'

const appReducer = combineReducers({
  browser,
  location: router,
  jobs,
  posts,
  events,
  updates,
})

export default appReducer
