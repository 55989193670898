import AOS from 'aos'
import '@fancyapps/fancybox'
import 'slick-carousel'

const close_navigation = () => {
  $('.submenu_overview a').on('click', function () {
    $('.is-opened').removeClass('is-opened')
  })
}

const resizeFullWidthRow = () => {
  $('[data-vc-full-width="true"]').each(function () {
    $(this).css({
      width: document.body.clientWidth,
      marginLeft: -document.body.clientWidth / 2,
    })
  })
}

const setBodyWidth = () => {
  let root = document.documentElement

  root.style.setProperty('--body-width', document.body.clientWidth + 'px')
}

const arrowToggle = () => {
  $('.arrow-toggle-js').on('click', function () {
    $(this).find('i').toggleClass('ion-ios-arrow-up ion-ios-arrow-down')
    $(this).parent().parent().find('.overnight-stay__item').fadeToggle()
  })
}

const arrowMapToggle = () => {
  $('.arrow-map-js').on('click', function () {
    $(this).find('i').toggleClass('ion-ios-arrow-up ion-ios-arrow-down')
    $(this).closest('.additional-item__toggle').next().fadeToggle('active')
  })
}

const detachModal = () => {
  $('.sticky-links .modal').each(function () {
    var modal = $(this).detach()
    $('.sticky-links').append(modal)
  })
}

const stickyBanner = () => {
  var lastScrollTop = 0
  var scrollTop = undefined
  var body = $('body')
  var distance = 30

  function handleScroll() {
    scrollTop = $(window).scrollTop()

    if (scrollTop < distance) {
      body.removeClass('sticky-nav')
      // $('.js-sticky-link').addClass('opened')
    } else {
      body.addClass('sticky-nav')
      // $('.js-sticky-link.opened').removeClass('opened')
    }

    lastScrollTop
  }

  handleScroll()
  $(window).on('scroll', throttled(10, handleScroll))
}

const throttled = (delay, fn) => {
  let lastCall = 0
  return function (...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

const smoothScroll = (link, e) => {
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }

  if (hash && $(hash).length) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top - 75,
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

const menuToggler = () => {
  const toggler = $('.js-primary-navigation-toggler')
  const headerMenu = $('.js-header-menu')
  toggler.on('click', function () {
    $(this).toggleClass('is-opened')
    $('body').toggleClass('primary-navigation-opened')
    const nav = $('#primary-navigation')
    if (nav.hasClass('is-opened')) {
      nav.find('.dropdown-menu.is-opened').removeClass('is-opened')
    }
    nav.toggleClass('is-opened')
  })

  headerMenu.on('click', '.js-submenu-close', function () {
    $(this).closest('.dropdown-menu.is-opened').toggleClass('is-opened')
  })

  headerMenu.on('click', '.js-dropdown-toggle', function (e) {
    e.preventDefault()
    const item = $(this)

    const itemTitle = item.html()
    const dropDownOpened = item.next('.dropdown-menu.is-opened').length

    item.closest('ul').find('.dropdown-menu.is-opened').removeClass('is-opened')

    if (!dropDownOpened) {
      item
        .next('.dropdown-menu')
        .addClass('is-opened')
        .children('.js-submenu-close')
        .html(itemTitle)
    }
  })

  $(document).mouseup(function (e) {
    if (
      !headerMenu.is(e.target) &&
      headerMenu.has(e.target).length === 0 &&
      !toggler.is(e.target) &&
      toggler.has(e.target).length === 0
    ) {
      toggler.removeClass('is-opened')
      $('body').removeClass('primary-navigation-opened')
      const nav = $('#primary-navigation')
      if (nav.hasClass('is-opened')) {
        nav.find('.dropdown-menu.is-opened').removeClass('is-opened')
      }
      nav.removeClass('is-opened')
    }
  })
}

const stageAnimationInit = () => {
  const TIME = 3 //in seconds
  const $stages = $('.js-stage')

  $stages.each(function () {
    const $stage = $(this)
    $.getJSON($stage.data('animation'), function (data) {
      if (data) {
        const steps = data['steps']
        const $svg = $stage.find('svg.animated')
        const $path = $svg.find('path')

        $path.css('transition-duration', TIME + 's')

        setTimeout(function () {
          $path.attr('d', steps[0])
        }, 100)

        let i = 1
        setInterval(function () {
          $path.attr('d', steps[i])
          i++

          if (i + 1 > steps.length) {
            i = 0
          }
        }, TIME * 1000)
      }
    })
  })
}

const modalInit = () => {
  $('body').on('click', '.js-modal-link', function (e) {
    e.preventDefault()
    const modalId = $(this).attr('href')
    $(modalId).modal('show')
  })

  $('.modal')
    .on('show.bs.modal', function () {
      const video = $(this).find('video')
      if (video.length) video[0].play()

      const $iframe = $(this).find('.wpb_video_wrapper iframe')

      if ($iframe.length) {
        $($iframe[0]).attr(
          'src',
          $($iframe[0]).attr('src') + '&rel=0&autoplay=1'
        )

        const $overlay = $($iframe[0])
          .closest('.wpb_video_wrapper')
          .find('.js-wpb-video-overlay')
        $overlay.fadeOut(300)
      }
    })
    .on('hide.bs.modal', function () {
      const video = $(this).find('video')
      if (video.length) video[0].pause()

      const $iframe = $(this).find('.wpb_video_wrapper iframe')

      if ($iframe.length) {
        $($iframe[0]).attr(
          'src',
          $($iframe[0]).attr('src').replace('&rel=0&autoplay=1', '')
        )

        const $overlay = $($iframe[0])
          .closest('.wpb_video_wrapper')
          .find('.js-wpb-video-overlay')
        $overlay.fadeIn(300)
      }
    })
}

const overlayedVideoEvents = () => {
  $('.js-wpb-video-overlay').on('click', function () {
    const $overlay = $(this)

    const $video = $overlay.closest('.wpb_video_wrapper').find('video')
    if ($video.length) $video[0].play()

    const $iframe = $overlay.closest('.wpb_video_wrapper').find('iframe')

    if ($iframe.length) {
      $iframe.attr('src', $iframe.attr('src') + '&rel=0&autoplay=1')
    }

    $overlay.fadeOut(300)
  })
}

const formsHandler = () => {
  $('input,textarea', '.gfield').each(function () {
    $(this).attr('placeholder', '')
    if (!$(this).closest('.gfield').find('.gfield_label').hasClass('active')) {
      if ($(this).val()) {
        $(this).closest('.gfield').find('.gfield_label').addClass('active')
      }
      $(this).on('focusin', function () {
        $(this).closest('.gfield').find('.gfield_label').addClass('active')
      })

      $(this).on('focusout', function () {
        if (!$(this).val()) {
          $(this).closest('.gfield').find('.gfield_label').removeClass('active')
        }
      })
    }
  })
}

const mobileFiltersToggle = () => {
  $('body').on('click', '.js-filters-dropdown-btn', function () {
    $(this).siblings('.js-filters-dropdown').slideToggle()
  })
}

const equalHeight = () => {
  $('.teasers-slider__carousel').each(function () {
    var highestBox
    if ($('.post-teaser__content', this).length > 1) {
      highestBox = 0
    }
    $('.post-teaser__content', this).each(function () {
      if ($(this).height() > highestBox) {
        highestBox = $(this).height()
      }
    })
    $('.post-teaser__content', this).height(highestBox)
  })
}

const equalLinkTextBoxesHeight = () => {
  const items = [...document.querySelectorAll('.dhsv_vc_link-text-box')]
  const wrappers = [
    ...document.querySelectorAll('.dhsv_vc_link-text-box .wrapper .inner'),
  ]

  const heights = wrappers
    .map(item => item.clientHeight)
    .sort((a, b) => (a > b ? -1 : 1))

  items.forEach(item => {
    item.style.height = heights[0] + 'px'
  })
}

const stickyLinksInit = () => {
  const links = $('.js-sticky-link')

  setTimeout(() => {
    links.removeClass('opened')
  }, 2500)
}

const searchToggle = () => {
  $.fn.textWidth = function (text, font) {
    if (!$.fn.textWidth.fakeEl)
      $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body)

    $.fn.textWidth.fakeEl
      .text(text || this.val() || this.text() || this.attr('placeholder'))
      .css('font', font || this.css('font'))

    return $.fn.textWidth.fakeEl.width()
  }

  const searchfield = $('#s')
  const searchBtn = $('.js-search-btn')

  searchBtn.on('click', function () {
    $(this).toggleClass('is-opened')
    $('.js-search-block').slideToggle()
    $('body').toggleClass('search-opened')
    searchfield.focus()
  })

  $('.js-searchform-input').on('click', function () {
    searchfield.focus()
  })

  searchfield
    .css({
      width: searchfield.textWidth(),
    })
    .on('input', function () {
      $(this).css({
        width: $(this).textWidth(),
      })
    })

  $(document).mouseup(function (e) {
    const searchBlock = $('.js-search-block > .container')
    if (
      !searchBlock.is(e.target) &&
      searchBlock.has(e.target).length === 0 &&
      !$('.js-banner').is(e.target) &&
      $('.js-banner').has(e.target).length === 0 &&
      $('body').hasClass('search-opened')
    ) {
      searchBtn.removeClass('is-opened')
      $('.js-search-block').slideUp()
      $('body').removeClass('search-opened')
    }
  })
}

const columnsSliderInit = () => {
  const viewport = window.bootstrapToolkit
  $('.js-vc_columns-slider').each(function () {
    const slider = $(this)
    const sliderArgs = {
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    const sliderCheck = () => {
      if (viewport.is('<=md')) {
        if (!slider.hasClass('slick-initialized')) {
          slider.slick(sliderArgs)
        }
      } else {
        if (slider.hasClass('slick-initialized')) {
          slider.slick('unslick')
        }
      }
    }

    sliderCheck()

    $(window).resize(
      viewport.changed(function () {
        sliderCheck()
      })
    )
  })
}

const translateBreadcrumb = () => {
  var lang_code = jQuery('html')[0].lang.split('-')
  if (lang_code[0] == 'en') {
    $('.breadcrumbs a.post').each(function () {
      var elementTitle = $(this).prop('title')
      elementTitle = elementTitle.replace('Referenzen', 'References')
      $(this).prop('title', elementTitle)
      var elementLabel = $(this).text()
      elementLabel = elementLabel.replace('Referenzen', 'References')
      $(this).text(elementLabel)
    })
  }
}

export default {
  init() {
    AOS.init()
    close_navigation()
    resizeFullWidthRow()
    setBodyWidth()
    stickyBanner()
    menuToggler()
    stageAnimationInit()
    modalInit()
    overlayedVideoEvents()
    detachModal()
    arrowToggle()
    arrowMapToggle()
    mobileFiltersToggle()
    equalHeight()
    equalLinkTextBoxesHeight()
    searchToggle()
    columnsSliderInit()
    // wrapContainerHeight()
    translateBreadcrumb()

    formsHandler()

    $(document).bind('gform_post_render', function () {
      formsHandler()
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function (e) {
        smoothScroll($(this).attr('href'), e)
      }
    )

    //scroll to bottom of the site on sitemap opened
    $('.site-map__link').on('click', function () {
      let $this = $(this)
      if ($this.attr('aria-expanded') == 'false') {
        $('html, body').animate({ scrollTop: $(document).height() }, 1000)
      }
    })

    $(
      '.sticky-links__items:not(.sticky-links__soc):not(.toggle-social-js):not(.socials__wrapper)'
    ).on('hover', function () {
      $(this).toggleClass('active')
    })

    $('.toggle-social-js')
      .on({
        mouseenter: function () {
          $('li.sticky-links__soc').slideDown(400)

          setTimeout(function () {
            $('.toggle-social-js').addClass('open')
          }, 800)
        },
        mouseleave: function () {
          $('li.sticky-links__soc').stop(true, true).slideUp(400)
          $(this).removeClass('open')
        },
      })
      .on('click', function (e) {
        e.preventDefault()
      })

    $('.sticky-links__soc a').click(function (e) {
      e.preventDefault()
      var href = $(this).attr('href')
      window.open(href, '', 'height=269,width=550,resizable=1')
    })

    if (window.location.hash) {
      smoothScroll(window.location.hash)
    }

    $(window).on('resize', function () {
      resizeFullWidthRow()
      setBodyWidth()
      equalHeight()
      equalLinkTextBoxesHeight()
    })

    window.equalLinkTextBoxesHeightfunction = equalLinkTextBoxesHeight

    // JavaScript to be fired on all pages

    let hoverDelayTimer
    $('body')
      .on('mouseenter', '.js-post-teaser', e => {
        let transitionDuration =
          parseFloat($(e.target).css('transition-duration')) * 1000
        hoverDelayTimer = setTimeout(() => {
          $(e.target).find('.js-excerpt').css('overflow', 'auto')
        }, transitionDuration)
      })
      .on('mouseleave', '.js-post-teaser', e => {
        if (hoverDelayTimer) {
          clearTimeout(hoverDelayTimer)
        }
        $(e.target).find('.js-excerpt').css('overflow', '')
      })
  },
  finalize() {
    stickyLinksInit()
    equalLinkTextBoxesHeight()
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
