import $ from 'jquery'
const viewport = window.bootstrapToolkit
let prevBreakpoint
$(document).ready(function () {
  dhsv_vc_intro(true)
})

$(window).resize(function () {
  dhsv_vc_intro(false)
})

function dhsv_vc_intro(first) {
  const currentBreakpoint = viewport.current()
  if (prevBreakpoint !== currentBreakpoint || first === true) {
    prevBreakpoint = currentBreakpoint
    $('.dhsv_vc_intro').each(function () {
      const $this = $(this)
      const mobileContainer = $this.find('.js-media-mobile')

      if (viewport.is('<=md') && mobileContainer.length) {
        const desktopContainer = $this.find(
          '.js-media-desktop .js-media-wrapper'
        )
        desktopContainer.detach().appendTo(mobileContainer)
      } else if (
        viewport.is('>md') &&
        first === false &&
        mobileContainer.length
      ) {
        const mobileContent = mobileContainer.find('.js-media-wrapper')
        const desktopContainer = $this.find('.js-media-desktop')
        mobileContent.detach().appendTo(desktopContainer)
      }
    })
  }
}
