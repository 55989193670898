import $ from 'jquery'
import SimpleLightbox from 'simplelightbox'

$(document).ready(function () {
  dhsv_vc_lightbox_image()
})

function dhsv_vc_lightbox_image() {
  $('.dhsv_vc_lightbox_image').each(function () {
    var $lightboximg = $(this).find('a')
    let lightbox = new SimpleLightbox($lightboximg, {
      nav: false,
      preloading: false,
    })
    // in vc_tta_accordion the lightbox closes the opened panel
    // re-add the vc_active class to panel if lightbox is in accordion
    lightbox.on('closed.simplelightbox', function () {
      var hash = window.location.hash
      if (hash != '') {
        $(hash).addClass('vc_active')
      }
    })
  })
}
